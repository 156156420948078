/* emails.js */

function add_email_row(self, prototype, advanced) {
  var tbody = $(self).parents('fieldset').find('tbody');
  prototype.advanced = advanced;
  $.mustacheLoader('email_address_row', function(template) {
    var row = $($.mustache(template, prototype));
    tbody.append(row);
    apply_alternate_row_color();
    row.find('input').first().focus();
  });
  return false;
}

window.summernote_popup_handler = function (event, command, self) {
  var field = self.closest('li').data('field');
  if (command == 'filepattern') {
    field.name = field.name.replace(/([<>:"|?*/\\])+/g, '');
  }
  $('#summernote').summernote('mailmerge.insert', field);
  event.preventDefault ? event.preventDefault() : event.returnValue = false;
}

function remove_email_row(self) {
  $(self).parents('tr').remove();
  return false;
}

window.toggle_hidden_recipients = function(self) {
  var toggle = $(self).closest('.recipient_toggle');
  toggle.hide();
  toggle.siblings('.hidden_recipient').fadeIn();
}

window.applicantEmailLoaded = function(iframe, textAreaSelector) {
  var doc = iframe.contentWindow.document;
  iframe.height = doc.body.scrollHeight + 10;
  if (textAreaSelector != undefined) {
    // Initially iframe src is empty, so we need to load the html body from the hidden input
    // After template change, we can load the html body from the iframe
    // iframe src path has id and number
    var hidden_input = document.querySelector ('input[name="' + textAreaSelector + '"]');
    if (iframe.src.match(/id=\d+/)) {
      hidden_input.value = iframe.contentWindow.$("#summernote").summernote('code');
    } else {
      var body = hidden_input.value;
      if (body.length > 0) {
        iframe.contentWindow.$("#summernote").summernote('code', body);
      }
    }
  }
}

window.toggle_application_email = function(self, id, subject) {
  var body = $('#email_' + id);
  if (self.hasClass('closed')) {
    if (body.attr('src') == undefined) body.attr('src', self[0].href)
    if (body.css('display') == 'none')
      body.slideDown();
    else {
      $('#email_' + id).load(self[0].href, null, function(xml_http_request) {
        apply_tipsy();
        body.slideDown();
        body.attr('tabindex', '0');
      });
    }
    self.removeClass('closed');
    self.addClass('open');
    self.attr('aria-label', subject + ' expanded');
    self.attr('aria-expanded', true);
  }
  else {
    body.slideUp();
    self.removeClass('open');
    self.addClass('closed');
    self.attr('aria-label', subject + ' collapsed');
    self.attr('aria-expanded', false);
  }
  return false;
}

$(document).on('click', '#mailmerge_summernote_dialog .pickable_field', function(event) {
  summernote_popup_handler(event, 'mailmerge', $(this));
});

$(document).ready(function() {
  $('#email_email_template_id').on('change', function(event) {
    var select = $(event.currentTarget);
    var form = select.closest('form');
    var subject = form.find('[name="email[subject]"]');
    var value = select.val();

    if (value) {
      $('iframe#editor').attr('src', '/email_templates/composer?id=' + value)
      $.getJSON('/email_templates/' + value, function(data, textStatus) {
        subject.val(data.email_subject);
        emailAttachmentsWidget.setFiles(data.email_attachments);
      });
    }
    else {
      $('iframe#editor').attr('src', '/emails/composer')
      subject.val('');
      emailAttachmentsWidget.setFiles([]);
    }
  });
});

$('form.new_email').on('submit', function() {
  var iframe = $('iframe#editor')[0]
  if (!iframe.contentWindow.$('#summernote').summernote('isEmpty')) {
    var value = iframe.contentWindow.$('#summernote').summernote('code');
    $('#email_html_body').attr('value', value)
  }
});
